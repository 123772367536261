<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<h5>{{$t('Mi Perfil')}}</h5>
				<div class="p-fluid p-formgrid p-grid" v-if="perfil">
					<div class="p-field p-col-12 p-md-8">
						<label>{{$t('Nombre Completo')}}</label>
						<InputText  v-model="perfil.nombre" type="text"/>
					</div>
					<div class="p-field p-col-12 p-md-2">
						<label>{{$t('Número ID')}}</label>
						<InputText v-model="perfil.code" type="text"/>
					</div>
                    <div class="p-field p-col-12 p-md-2">
                    <label>{{$t('Fecha de nacimiento')}}</label>
						<InputMask v-model="perfil.fechnac" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                    </div>
					<div class="p-field p-col-12 p-md-5">
						<label>{{$t('Correo')}}</label>
						<InputText v-model="perfil.correo" type="email"/>
					</div>
					<div class="p-field p-col-12 p-md-2">
						<label>{{$t('Teléfono móvil')}}</label>
						<InputMask v-model="perfil.celular" mask="+99999 999999" />
						<small class="p-error" v-if="i18n.locale() == 'es'">Incluir código del país</small>
						<small class="p-error" v-if="i18n.locale() == 'en'">Include country code</small>                    
					</div>
					<div class="p-field p-col-12 p-md-2">
						<label>{{$t('Teléfono privado')}}</label>
						<InputMask v-model="perfil.tlflocal" mask="+99999 999999" />
						<small class="p-error" v-if="i18n.locale() == 'es'">Incluir código del país</small>
                        <small class="p-error" v-if="i18n.locale() == 'en'">Include country code</small>                    
					</div>
					<div class="p-field p-col-12 p-md-3">
						<label>{{$t('Localidad')}}</label>
                        <InputText v-model="localidad.nombre" type="text" disabled v-if="i18n.locale() == 'es'"/>
                        <InputText v-model="localidad.name" type="text" disabled v-if="i18n.locale() == 'en'"/>
					</div>
					<div class="p-field p-col-12">
						<label>{{$t('Dirección')}}</label>
						<Textarea v-model="perfil.direccion" rows="2"/>
					</div>
					<div class="p-field p-col-4 p-md-2">
						<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="Save" />
					</div>
					<div class="p-field p-col-4 p-md-2">
						<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="Mostrar"/>
					</div>
					<div class="p-field p-col-4 p-md-2">
						<FileUpload :chooseLabel="$t('Subir Foto')" uploadLabel="Cargar" mode="basic" :name="cifrado" :url="url.api+'foto.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader" :auto="true"/>
                        <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small>
					</div>
					{{ url.api }}
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import API from "../service/API";
	const Consulta = new API('Autenticacion','MiPerfil');
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import url from "../service/_URL";
	import Token from "uuid-token-generator";

	export default {
		data() {
			return {
				i18n: null,
				size: '60vw',
				perfil: null,
				nacionalidades: null,
				localidad: null,
				profesiones: null,
				sexos: null,
				url: null,
				cifrado: null,
				sincronizar: false,
			}
		},
		created() {
			this.url = url;
			this.i18n = useI18nPlugin();
			this.Mostrar();
			const tokgen = new Token();
			this.cifrado = tokgen.generate()+'_'+this.$store.state.id;
		},
		methods: {
			myUploader() {
				this.sincronizar = true;		
				Consulta.Procesar('Foto',{
					foto: this.$store.state.foto,
					cifrado: this.cifrado,
				});
				this.Sincroniza();
			},
			Mostrar() {
				this.sincronizar = false;
				Consulta.Ini().then(result => {
					this.nacionalidades = result.consult.nacionalidades;
					this.localidad = result.consult.localidad;
					this.sexos = result.consult.sexos;
					this.profesiones = result.consult.profesiones;
					this.perfil = result.consult.perfil;
                    this.nacionalidades.forEach(element => {
                        if(this.perfil.nacionalidad==element.code){
                            this.perfil.nacionalidad = element;
                        }
                    });  
                    this.profesiones.forEach(element => {
                        if(this.perfil.profesion==element.code){
                            this.perfil.profesion = element;
                        }
                    });  
                    this.sexos.forEach(element => {
                        if(this.perfil.sexo==element.code){
                            this.perfil.sexo = element;
                        }
                    });  
					this.perfil.fechnac = Consulta.fstring(this.perfil.fechnac);
				}); 			
			},
			GuardarFoto(){
				this.sincronizar = true;		
				Consulta.Procesar('Foto',{
					foto: this.$store.state.foto,
					cifrado: this.cifrado,
				});
				this.Sincroniza();
			},
			Sincroniza(){
				this.sincronizar = true;		
				Consulta.Procesar('RevFoto',{
					foto: this.cifrado,
					viejo: this.$store.state.foto
				}).then(response => {
					if (response.result) {
						this.$store.state.foto = response.result;
						const tokgen = new Token();
						this.cifrado = tokgen.generate()+'_'+this.$store.state.id;
					}
				});
			},
			Save() {
				if (this.perfil.nombre == null || this.perfil.nombre == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Nombre'), life: 10000});
				} else if (this.perfil.correo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Correo'), life: 10000});
				} else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
					test(this.perfil.correo)) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Correo'), life: 10000});
				} else if (this.perfil.fechnac == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Fecha de Nacimiento'), life: 5000});
				} else if (this.perfil.celular == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Móvil'), life: 5000});
				} else if (this.perfil.tlflocal == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Tlf Privado'), life: 5000});
				} else if (this.perfil.code == null || this.perfil.code == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error')+' '+this.$t('Número ID'), life: 5000});
				} else if (this.perfil.localidad == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Localidad'), life: 5000});
				} else if (this.perfil.direccion == null || this.perfil.direccion == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Dirección'), life: 5000});
				} else {
					Consulta.Procesar('UPD',{
						code: this.perfil.code,
						nombre: this.perfil.nombre,
						correo: this.perfil.correo,
						fechnac: Consulta.fdate(this.perfil.fechnac),
						celular: this.perfil.celular,
						tlflocal: this.perfil.tlflocal,
						direccion: this.perfil.direccion
					}).then(response => {
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: this.$t('Éxito'), 
								detail: this.$t('Información Procesada'), 
								life: 3000
							});
							this.$store.commit('Perfil',this.perfil);
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('No fue procesada la solicitud'), 
								life: 3000
							});
						}
						this.Mostrar();
					});
				}
			}
		}
	}
</script>
